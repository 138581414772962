<template>
  <div class="card card-icon mb-4">
    <div class="row no-gutters">
      <!-- Icon -->
      <div
        class="d-none
            d-md-flex
            col-auto
            card-icon-aside
            bg-primary
            text-white"
      >
        <i data-feather="check-square"></i>
      </div>

      <div class="col">
        <div class="card-body py-4">
          <h3 class="card-title">
            <span :class="isEditingResource ? 'text-danger' : ''">{{ titleForm }}</span>
          </h3>

          <div class="card-text">
            <FormulateForm
              ref="catalogoProyectosForm"
              @submit="submit"
              #default="{ hasErrors, isLoading }"
            >
              <b-row>
                <!-- Periodo fiscal -->
                <b-col cols="12" md="12" lg="6">
                  <FormulateInput
                    name="periodo_fiscal"
                    v-model="formValues.periodo_fiscal_id"
                    :disabled="isLoading"
                    validation="bail|required"
                    error-behavior="live"
                    :validation-messages="{
                      required: 'El periodo fiscal es requerido'
                    }"
                  >
                    <template #element>
                      <x-form-group title="Periodo fiscal" required>
                        <check-authorization
                          :requiresAuthorizations="['listar periodos fiscales']"
                          :show-alert="false"
                          no-slots
                          #default="{ authorized, message }"
                        >
                          <span v-b-tooltip.top :title="authorized ? '' : message">
                            <x-select
                              v-model="formValues.periodo_fiscal_id"
                              dispatchPath="fiscalPeriodsModule/getFiscalPeriods"
                              getterListPath="fiscalPeriodsModule/getAllFiscalPeriods"
                              :formatListWith="['id', 'nombre']"
                              no-prepend
                              no-validate
                              :disabled="!authorized"
                            ></x-select>
                          </span>
                        </check-authorization>
                      </x-form-group>
                    </template>
                  </FormulateInput>
                </b-col>

                <!-- Clave -->
                <b-col cols="12" md="12" lg="6">
                  <x-form-group title="Clave" required>
                    <FormulateInput
                      type="text"
                      name="clave"
                      v-model="formValues.clave"
                      :disabled="isLoading"
                      placeholder="Ingresa el número de clave"
                      validation="bail|required|max:100,length"
                      :validation-messages="{
                        required: 'La clave es requerida',
                        max: 'La longitud máxima es de 100 caracteres'
                      }"
                    />
                  </x-form-group>
                </b-col>

                <!-- Nombre -->
                <b-col cols="12" md="12" lg="6">
                  <x-form-group title="Nombre" required>
                    <FormulateInput
                      v-model="formValues.nombre"
                      :disabled="isLoading"
                      placeholder="Ingresa el nombre del proyecto"
                      validation="bail|required|max:255,length"
                      :validation-messages="{
                        required: 'El nombre del proyecto es requerido',
                        max: 'La longitud máxima es de 255 caractéres'
                      }"
                    />
                  </x-form-group>
                </b-col>

                <!-- Estatus -->
                <b-col cols="12" md="12" lg="6">
                  <x-form-group title="Estatus">
                    <div class="form-group">
                      <b-form-select
                        :value="formValues.estatus.value"
                        v-model="formValues.estatus"
                        :options="[
                          { text: 'Activo', value: 1 },
                          { text: 'Inactivo', value: 0 }
                        ]"
                      ></b-form-select>
                    </div>
                  </x-form-group>
                </b-col>
              </b-row>

              <!-- Enviar para crear o actualizar -->
              <x-form-footer-buttons
                :disabled="hasErrors || isLoading"
                :loading="isLoading"
                :isEditing="isEditing"
                :isCreating="!isEditing"
                @on-cancel="resetForm"
                @on-click-create="submit"
                @on-click-update="submit"
              ></x-form-footer-buttons>
            </FormulateForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

const formValues = () => ({
  nombre: '',
  clave: '',
  estatus: '',
  periodo_fiscal_id: ''
})

export default {
  name: 'ProyectoForm',

  data () {
    return {
      formValues: formValues(),

      isEditing: false
    }
  },

  computed: {
    ...mapGetters('proyectoModule', ['isEditingResource']),

    ...mapGetters('fiscalPeriodsModule', ['getFiscalPeriodsForForms']),

    ...mapState('proyectoModule', ['currentProyecto']),

    titleForm () {
      return this.isEditingResource
        ? `Editando proyecto: ${this.currentProyecto.nombre}`
        : 'Crear proyecto'
    }
  },

  watch: {
    currentProyecto: {
      deep: true,
      immediate: true,
      handler (currentProyecto) {
        this.isEditing = Boolean(currentProyecto)

        if (this.isEditing) {
          this.formValues = currentProyecto
          return
        }

        this.resetForm()
      }
    }
  },

  methods: {
    emitOnHideForm () {
      this.$emit('hide-form')
    },

    emitOnSubmitted () {
      this.$emit('submitted')
    },

    async submit () {
      const { error, message } = this.isEditing
        ? await this.updateProyecto(this.formValues)
        : await this.createProyecto(this.formValues)

      this.$notify({ error, message }, 'Proyectos')

      if (error) return

      this.emitOnSubmitted()
      this.resetForm()
    },

    async createProyecto (form) {
      return await this.$store.dispatch('proyectoModule/createProyecto', form)
    },

    async updateProyecto (form) {
      return await this.$store.dispatch('proyectoModule/updateProyecto', {
        id: form.id,
        payload: form
      })
    },

    resetForm () {
      document.activeElement.blur()
      this.$store.commit('proyectoModule/setCurrentProyecto', null)
      this.formValues = formValues()
      if (this.$refs.catalogoProyectosForm) {
        this.$refs.catalogoProyectosForm.hideErrors()
        this.emitOnHideForm()
      }
      window.scrollTo(0, 0)
    }
  }
}
</script>
