<template>
  <base-view title="Proyectos" icon="check-square">
    <!-- Muestra el periodo fiscal activo -->
    <template #right-place>
      <div class="row">
        <x-periodo-fiscal-tag classBody="col-4 offset-8 right-content" variant="primary" />
      </div>
    </template>

    <!-- Formulario -->
    <check-authorization
      :requiresAuthorizations="[permissions.create]"
      :overrideIf="$store.getters['proyectoModule/isEditingResource']"
      hide
    >
      <proyecto-form
        v-show="showForm || isEditingResource"
        @hide-form="onHideForm"
        @submitted="getListadoProyectos"
      />
    </check-authorization>

    <!-- Listado -->
    <check-authorization :requiresAuthorizations="[permissions.list]">
      <div class="card card-header-actions">
        <div class="card-header">
          Listado de proyectos
          <administrative-unit-search class="ml-auto"></administrative-unit-search>
          <x-btn-with-auth
            v-show="!showForm"
            :requiresAuthorizations="[permissions.create]"
            :callback="cancel"
            variant="success"
            btnClass="ml-auto m-md-2"
          >
            {{ !showForm ? 'Crear' : 'Ocultar' }}
          </x-btn-with-auth>
          <b-dropdown id="dropdown-1" text="Exportar" class="m-md-2" size="sm">
            <b-dropdown-item @click="download('xls')">XLS</b-dropdown-item>
            <b-dropdown-item @click="download('pdf')">PDF</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="card-body p-3">
          <proyecto-list :permissions="permissions" @input="onChangePeriodoFiscal" />
        </div>
      </div>
    </check-authorization>
  </base-view>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProyectoForm from '@/components/Catalogos/Proyectos/ProyectoForm'
import ProyectoList from '@/components/Catalogos/Proyectos/ProyectoList'

export default {
  name: 'ProyectosView',

  components: {
    ProyectoForm,
    ProyectoList
  },

  created () {
    this.permissions = this.$router.currentRoute.meta.permissions
  },

  data () {
    return {
      permissions: {},
      showForm: false,
      periodo_fiscal_id: null
    }
  },

  computed: {
    ...mapGetters('proyectoModule', ['isEditingResource', 'getCurrentEditing'])
  },

  methods: {
    ...mapActions('proyectoModule', ['getProyectos']),

    async getListadoProyectos () {
      const { error, message } = await this.getProyectos(this.periodo_fiscal_id)

      if (error) this.$notify({ error, message }, 'Listado de proyectos')
    },

    cancel () {
      this.showForm = !this.showForm
    },

    onChangePeriodoFiscal (id) {
      this.periodo_fiscal_id = id

      this.getListadoProyectos()
    },

    onHideForm () {
      this.showForm = false
    },

    async download (formato) {
      const getval = await this.$store.dispatch('proyectoModule/export', {
        periodo_id: this.periodo_fiscal_id,
        formato: formato
      })

      await this.$onDownload(getval, `proyectos.${formato}`)

      this.$notify({ message: 'Listado de proyectos generado' })
    }
  }
}
</script>
